// @flow
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as masterDataActions } from '../../state/actions/masterData';

type VehicleDetailsHook = {
  onSubmit: Function,
  onSelectChangeValue: Function,
  toggleUploadImageSectionState: Function,
  updateVehicle: Function,
};

export function useVehicleDetails(routeId): VehicleDetailsHook {
  const dispatch = useDispatch();

  const vehicleStore = useSelector(state => state.masterData.vehicle);

  const [vehicle, setVehicle] = useState(vehicleStore);
  const [vinLookupInput, setVinLookupInput] = useState();

  const actionErrors = useSelector(state => state.masterData.errors);
  const isLoading = useSelector(state => state.masterData.isLoading);
  const models = useSelector(state => state.masterData.models);
  const masterData = useSelector(state => state.masterData.masterData);

  const [
    uploadImageSectionToggleExpanded,
    setToggleUploadImageSection,
  ] = useState(false);

  useEffect(() => {
    dispatch(masterDataActions.loadVehicleDetails(!routeId, routeId));
    setToggleUploadImageSection({ uploadImageSectionToggleExpanded: false });
    return function cleanup() {
      setVehicle({});
      setToggleUploadImageSection({});
    };
  }, [dispatch, routeId]);

  useEffect(() => {
    setVehicle(vehicleStore);
  }, [vehicleStore]);

  const updateVehicle = updatedVehicle => {
    setVehicle(updatedVehicle);
  };

  const onSubmit = updatedVehicle => {
    dispatch(masterDataActions.saveVehicle(!routeId, updatedVehicle));
  };

  const toggleUploadImageSectionState = () => {
    setToggleUploadImageSection(!uploadImageSectionToggleExpanded);
  };

  const handleVinLookup = () => {
    dispatch(masterDataActions.loadVehicleDetails(true, vinLookupInput));
  };

  const updateVin = updatedVin => {
    setVinLookupInput(updatedVin);
  };

  return {
    onSubmit,
    uploadImageSectionToggleExpanded,
    toggleUploadImageSectionState,
    vehicle,
    updateVehicle,
    handleVinLookup,
    updateVin,
    vinLookupInput,
    actionErrors,
    routeId,
    isLoading,
    models,
    masterData,
  };
}
