// @flow
import React from 'react';
import styled from 'styled-components';

import arrow from '../../assets/arrow-down-icn.svg';

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    background-color: #ffffff;
    height: 100%;
    min-width: 149px;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
`;

const Title = styled.div`
  color: #777777;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 2px;
  padding-left: 14px;
`;

const Triangle = styled.img`
  padding-right: 14px;
`;

type Props = {
  title: string;
}

export default function (props: Props) {
  const { title } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <Triangle src={arrow} alt="arrow" />
    </Container>
  );
}
