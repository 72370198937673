const widget = `
  #cloudinary-overlay.inline .widget {
    border: 2px dashed #ddd;
    background-color: #efefef;
  }
`;

const hideNavBar = `
  #cloudinary-navbar {
    display: none;
  }
`;

const panel1 = `
  #cloudinary-widget .panel.active {
    margin: 0;
    background-color: #efefef;
  }
`;

const panel2 = `
  #cloudinary-widget .panel {
    height: 120px;
  }
`;

const dragArea = `
  #cloudinary-widget .drag_area {
    margin: 0;
    background-color: #efefef;
    height: 120px;
    box-sizing: border-box;
    border: none;
  }
`;

const dragContent = `
  #cloudinary-widget .drag_content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const dragContentLabel = `
  #cloudinary-widget .panel.local .drag_area .drag_content .label {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 8px;
    color: #2F3031;
  }
`;

const orLabel = `
  #cloudinary-widget .drag_content .or {
    display: none;
  }
`;

const fileUploadButton = `
  #cloudinary-widget .drag_content .upload_button_holder {
    height: 30px;
    border-radius: 4px;
  }
`;

const fileUploadAnchors = `
  #cloudinary-widget .drag_content .upload_button_holder a {
    font-size: 12px;
    font-weight: normal;
    padding: 0;
    background-color: #175F5B;
  }
`;

const styledUploadButton = `
  #cloudinary-widget .upload_button_holder .button {
    font-size:16px;
    background-color:#0065DB;
    color:#FFFFFF;
    border:0;
    border-radius: 0;
    font-weight:bold;
    cursor: pointer;
  }
`;

const error = `
  #cloudinary-widget .error {
    display: block
  }
`;

const thumbnails = `
  #cloudinary-widget .thumbnails.clear {
    height: 60px;
  }`;

const errorDecoration = `
  #cloudinary-widget .image, .image_cover, .file_name {
    display: none;
  }`;

const imageHolder = `
  #cloudinary-widget .image_holder {
    border: none;
  }`;

export default `${widget}${hideNavBar}${panel1}${panel2}${dragArea}${dragContent}${dragContentLabel}${orLabel}${fileUploadButton}${fileUploadAnchors}${styledUploadButton}${error}${thumbnails}${errorDecoration}${imageHolder}`;
