// @flow
/* eslint-disable import/no-webpack-loader-syntax */
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Calendar from '../../assets/calendar-icn.svg';
import { FormField } from '.';

import './FormDatepicker.css';

const Container = styled.div`
  margin-top: 12px;
  margin-bottom: 5px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CalendarIcon = styled.img`
  cursor: pointer;
  margin-left: 11px;
  height: 17px;
`;

type Props = {
  label: string,
  name: string,
  value: Date,
  keyValue: string,
  onChange: Function,
  required: boolean,
  dateFormat: string,
  onSelect: (keyValue: string, value: Date) => void,
  error?: string,
  minDate: Date,
  maxDate: Date,
};

export default class FormDatepicker extends React.Component<Props, *> {
  constructor(props) {
    super(props);
    this.datepicker = React.createRef();
  }

  render() {
    const {
      label,
      name,
      value,
      onSelect,
      keyValue,
      required,
      dateFormat,
      error,
      minDate,
      maxDate,
    } = this.props;

    const onChange = e => {
      onSelect(keyValue, e);
    };

    return (
      <Container>
        <InputWrapper>
          <FormField
            label={label}
            required={required}
            error={error}
            direction="row"
          >
            <CalendarWrapper>
              <DatePicker
                dateFormat={dateFormat}
                name={name}
                selected={Date.parse(value)}
                onSelect={onChange}
                onChange={onChange}
                placeholderText="Click to select a date"
                className={
                  error ? 'datepicker-input error' : 'datepicker-input'
                }
                minDate={minDate}
                maxDate={maxDate}
                ref={this.datepicker}
              />
              <CalendarIcon
                src={Calendar}
                onClick={() => this.datepicker.current.setOpen(true)}
              />
            </CalendarWrapper>
          </FormField>
        </InputWrapper>
      </Container>
    );
  }
}
