// @flow
import React from 'react';
import styled from 'styled-components';
import { FormField } from '.';

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 25px;
  background: #ffffff;
  font-size: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;

  border: 1px solid #dbdbdb;
  &::before {
    content: ${props =>
      props.leftToggleLabel ? `"${props.leftToggleLabel}"` : '"YES"'};
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
  }
  &::after {
    content: ${props =>
      props.rightToggleLabel ? `"${props.rightToggleLabel}"` : '"NO"'};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 23px;
    margin: 1px;
    background: ${props => (props.internal ? '#9EADBA;' : '#007aff')};
    color: #ffffff;
    transition: 0.1s;
    margin-left: 29px;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  width: 60px;
  height: 25px;
  font-size: 11px;
  &:checked + ${CheckBoxLabel} {
    background: #fff;
    &::before {
      content: ${props =>
        props.rightToggleLabel ? `"${props.rightToggleLabel}"` : '"NO"'};
      margin-left: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &::after {
      content: ${props =>
        props.leftToggleLabel ? `"${props.leftToggleLabel}"` : '"YES"'};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 23px;
      margin-left: 1px;
      transition: 0.2s;
    }
  }
`;

type Props = {
  labelText: string,
  required: boolean,
  error?: string,
  onChange: (keyValue: string, value: boolean) => void,
  value: boolean,
  keyValue: string,
  leftToggleLabel: string,
  rightToggleLabel: string,
  internal: Boolean,
};

export default function({
  labelText,
  required,
  error,
  onChange,
  value,
  keyValue,
  leftToggleLabel,
  rightToggleLabel,
  internal,
}: Props) {
  return (
    <FormField label={labelText} required={required} error={error}>
      <CheckBoxWrapper>
        <CheckBox
          id={`${keyValue}CheckBox`}
          type="checkbox"
          onChange={() => onChange(keyValue, !value)}
          checked={value}
          leftToggleLabel={leftToggleLabel}
          rightToggleLabel={rightToggleLabel}
        />
        <CheckBoxLabel
          htmlFor={`${keyValue}CheckBox`}
          leftToggleLabel={leftToggleLabel}
          rightToggleLabel={rightToggleLabel}
          internal={internal}
        />
      </CheckBoxWrapper>
    </FormField>
  );
}
