// @flow
import React from 'react';
import styled from 'styled-components';
import { FormField } from '.';

import Checkbox from './RoundInput';

const Container = styled.div`
  margin-bottom: 15px;
`;

const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`;

const OptionDescription = styled.div`
  margin-left: 11px;
  font-size: 12px;
`;

type Option = {
  description: string,
  value: any,
};

type Props = {
  label: string,
  keyValue: string,
  options: Option[],
  value: string,
  onChange: Function,
  required: Boolean,
};

export default (props: Props) => {
  const {
    options,
    keyValue,
    value,
    onChange,
    label,
    required,
  } = props;
  return (
    <Container>
      <FormField label={label} required={required} >
        <OptionsContainer>
          {options.map(option => (
            <OptionWrapper key={option.description}>
              <Checkbox
                checked={value === option.value}
                onClick={() => onChange(keyValue, option.value)}
              />
              <OptionDescription>{option.description}</OptionDescription>
            </OptionWrapper>
          ))}
        </OptionsContainer>
      </FormField>
    </Container>
  );
};
