// @flow
import React from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import GridControls from './TableControls';

const Table = styled.table`
  width:100%;
  border-spacing: 0px;
`;

const THead = styled.thead``;

const TRow = styled.tr`
  :nth-child(even) {
    background-color: #FFFFFF;
  }
  :nth-child(odd) {
    background-color: #F0F0F0;
  }
  :last-child {
      box-shadow: inset -1px -1px 0 0 #CACACA;
  }
`;

const THeadColumn = styled.th`
  padding: 0px;
  width: 85px;
`;

const TColumn = styled.td`
  padding: 0px;
  box-shadow: inset -1px 0 0 0 #CACACA;
`;

const TBody = styled.tbody``;

export default function DynamicTable({
  columns,
  data,
  updateMyData,
  fetchData,
  pageCount,
  pageNumber,
  vehiclesCount,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1 },
      manualPagination: true,
      pageCount,
      updateMyData,
    },
    usePagination,
  );
  return (
    <>
      <GridControls
        pageCount={pageCount}
        pageIndex={pageNumber}
        pageOptions={pageOptions}
        gotoPage={fetchData}
        vehiclesCount={vehiclesCount}
      />
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map(headerGroup => (
            <TRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <THeadColumn {...column.getHeaderProps()}>
                  {column.render('Header')}
                </THeadColumn>
              ))}
            </TRow>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <TRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TColumn {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TColumn>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
      </Table>
    </>
  );
}
