// @flow
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '.';
import spinner from '../../assets/spinner-small-white.svg';

const SearchButton = styled(PrimaryButton)`
  position: absolute;
  right: 1px;
  top: 1px;
  min-width: 105px;
`;
const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 37px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#FFFFFF')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  :focus {
    outline: none;
  }
  padding-left: 35px;
`;

const Image = styled.img`
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
  height: 18px;
  width: 18px;
`;

const InputError = styled.label`
  position: absolute;
  display: ${props => (props.error ? 'inline-block' : 'none')};
  width: auto;
  color: #b91a2e;
  margin: auto;
  margin-right: 5px;
  right: 110px;
  top: 35%;
`;

type Props = {
  name: string,
  searchText: string,
  buttonText: string,
  icon: string,
  onSearch: Function,
  onSearchTextUpdate: Function,
  label: string,
  required: boolean,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  isLoading: boolean,
  keyValue: String,
};

const enterCharCode = 69;

const onKeyDown = (evt, searchText, onSearch) => {
  const keyCode = evt.key.charCodeAt(0);
  if (keyCode === enterCharCode) {
    evt.preventDefault();

    onSearch(searchText);
  }
};

const doSearch = (event, searchText, onSearch) => {
  event.preventDefault();

  onSearch(searchText);
};

export default function({
  name,
  searchText,
  onSearch,
  onSearchTextUpdate,
  buttonText,
  icon,
  required,
  keyValue,
  error,
  disabled,
  placeholder,
  truncateChars,
  isLoading,
}: Props) {
  return (
    <>
      <InputWrapper>
        <Image src={icon} alt="searchIcon" />
        <Input
          name={name}
          value={searchText}
          onChange={evt => onSearchTextUpdate(evt.target.value)}
          onKeyDown={evt => onKeyDown(evt, searchText, onSearch)}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          truncateChars={truncateChars}
          error={error}
          required={required}
          keyValue={keyValue}
        />
        <InputError error={error}>{error && error.message}</InputError>
        <SearchButton onClick={event => !isLoading && doSearch(event, searchText, onSearch)}>{ !isLoading ? buttonText : <img src={spinner} alt="Loading.." /> }</SearchButton>
      </InputWrapper>
    </>
  );
}

Input.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  value: '',
  label: '',
  error: '',
  truncateChars: false,
  isLoading: false,
};
