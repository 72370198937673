// @flow
import axios from 'axios';

const defaultConfig = ({ token }: { token: string }) => ({
  responseType: 'json',
  headers: token ? { Authorization: `Bearer ${token}` } : {},
});

type Options = {
  url: string,
  config: Object,
  data?: Object,
  token: string,
};

export function get(options: Options) {
  const { url, config = {}, token } = options;
  return axios.get(url, { ...defaultConfig({ token }), ...config });
}

export function post(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.post(url, data, { ...defaultConfig({ token }), ...config });
}

export function put(options: Options) {
  const { url, config = {}, data, token } = options;
  return axios.put(url, data, { ...defaultConfig({ token }), ...config });
}
