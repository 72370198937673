// @flow
import { constants } from '../actions/vehicles';
import { Error } from '../types/error';

export type Vehicle = {
  id: string,
  name: string,
};

export type State = {
  isLoading: boolean,
  error: ?Error,
  vehicles: Vehicle[],
  batchVehicles: Vehicle[],
  vehiclesCount: number,
  pageTotal: number,
};

const initialState: State = {
  isLoading: false,
  error: null,
  vehicles: [],
  batchVehicles: [],
  vehiclesCount: 0,
  pageTotal: 0,
};

const mapVehicleObjects = (foundBatch, vehicle) => {
  return {
    ...foundBatch,
    ...vehicle,
    price: { ...foundBatch.price, ...vehicle.price },
  };
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadVehicles:
      return { ...state, isLoading: true };

    case constants.loadVehiclesSuccess: {
      const {
        contents: vehiclesResult,
        total: totalVehicleCount,
        pageNumber,
        pageSize,
      } = action.payload;
      return {
        ...state,
        vehicles: vehiclesResult,
        vehiclesCount: totalVehicleCount,
        error: null,
        isLoading: false,
        pageTotal: Math.ceil(totalVehicleCount / pageSize),
        pageNumber,
        pageSize,
      };
    }
    case constants.loadVehiclesFailure:
      return { ...state, error: action.payload, isLoading: false };
    case constants.updateGridVehicles: {
      const vehicle = action.payload;
      return {
        ...state,
        vehicles: state.vehicles.map(v => (v.id === vehicle.id ? vehicle : v)),
      };
    }
    case constants.updateBatchVehicles: {
      const vehicle = action.payload;
      const foundBatch = state.batchVehicles.find(v => v.id === vehicle.id);
      return {
        ...state,
        batchVehicles: [
          ...state.batchVehicles.filter(v => v.id !== vehicle.id),
          foundBatch ? mapVehicleObjects(foundBatch, vehicle) : vehicle,
        ],
      };
    }
    case constants.updateVehiclesSuccess: {
      return {
        ...state,
        batchVehicles: [],
      };
    }
    default:
      return state;
  }
}
