// @flow
import React from 'react';
import styled from 'styled-components';

import './Chevron.css';

const ChevronWrapper = styled.div`
  color: #175F5B;
  border: 1px solid #175F5B;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 13px;
  padding-top: 3px;
`;

const StyledChevron = styled.div`
  content:'<';
  &::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: "";
    display: inline-block;
    transform: rotate(-45deg);
    margin-top: 8px;
  }
`;

const LeftChevron = () => {
  return (
    <ChevronWrapper>
      <StyledChevron className="chevron left" />
    </ChevronWrapper>);
};

export { LeftChevron };
