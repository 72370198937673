// @flow
import React from 'react';
import { Header, ButtonsWrapper, ButtonsContainer } from '../Common';

export default function() {
  return (
    <Header title="Feed Audit">
      <ButtonsWrapper>
        <ButtonsContainer />
      </ButtonsWrapper>
    </Header>
  );
}
