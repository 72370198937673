// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as uuid } from 'uuid';

const MediaUploadContainer = styled.div`
  max-height: 150px;
  width: 100%;
  overflow-y: hidden;
`;

type MediaType = 'image' | 'video';
type MediaResource = {
  version: string,
  type: string,
  format: string,
  public_id: string,
  existing: boolean,
  bytes: number,
  height: number,
  width: number,
  thumbnail_url: string,
  tags: string[],
  resource_type: MediaType,
};

type CloudinaryProps = {
  allowedTypeFormats: string[],
  onUploadMediaFailure: (error: string) => void,
  onUploadMediaSuccess: (resources: MediaResource[]) => void,
  accountName: string,
  preset: string,
  tags: string[],
  dropFileText: string,
  buttonText: string,
  stylesheet: string,
  openWidget: boolean,
};

export default ({
  allowedTypeFormats,
  onUploadMediaFailure,
  onUploadMediaSuccess,
  accountName,
  preset,
  tags,
  dropFileText,
  buttonText,
  stylesheet,
  openUploadWidget,
}: CloudinaryProps) => {
  const [uploadWidget, setUploadWidget] = useState();

  const id = `id-${uuid.v4()}`;

  useEffect(() => {
    const createUploadWidget = () => {
      setUploadWidget(
        window.cloudinary.createUploadWidget(
          {
            cloud_name: accountName,
            theme: 'minimal',
            tags,
            upload_preset: preset,
            client_allowed_formats: allowedTypeFormats,
            show_powered_by: false,
            inline_container: `#${id}`,
            sources: ['local'],
            stylesheet,
            text: {
              'sources.local.drop_files': dropFileText,
              'sources.local.select_files': buttonText,
            },
          },
          (error, result) => {
            if (error) {
              onUploadMediaFailure(error.message);
            } else {
              onUploadMediaSuccess(result);
            }
          },
        ),
      );
    };

    createUploadWidget();
  }, []);

  useEffect(() => {
    (openUploadWidget && uploadWidget && uploadWidget.open()) ||
      (uploadWidget && uploadWidget.close());
  }, [openUploadWidget, uploadWidget]);

  return (
      <MediaUploadContainer id={id} />
  );
};
