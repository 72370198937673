import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { pathOr } from 'ramda';
import { defaultLanguageCode } from '../../settings';

const Img = styled.img`
  max-height: 75px;
  flex-shrink: 0;
`;

const styledInputFactory = input => styled(input)`
  width: 100px;
  height: 34px;
  padding: 0 11px 0 8px;
`;

const StyledNumberInput = styledInputFactory(NumberFormat);

const ImgCell = styled.div`
  cursor: ${props => (props.isButton ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
`;

const HeaderCell = styled.div`
  background-color: #293845;
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
  color: #ffffff;
  font-size: 10px;
  line-height: 13px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

const CellWrapper = styled.div`
  display: flex;
  min-width: 100px;
  cursor: ${props => (props.isButton ? 'pointer' : 'default')};
  padding: 0 10px 0 10px;
  justify-content: center;
`;

const TextCell = styled.div`
  color: #2f3031;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: inherit;
`;

type TableConfigurationParams = {
  onRowClick: Function,
  EditableCell: Function,
};

export default function TableConfiguration(
  onRowClick,
  EditableCell: TableConfigurationParams,
) {
  function GetLocalCode(localeCode) {
    return localeCode.replace('_', '-');
  }

  function GetFormatedDate(dateString) {
    const date = moment(
      dateString,
      moment.ISO_8601,
      GetLocalCode(defaultLanguageCode),
    );
    return date.isValid() ? date.format('L') : '';
  }

  const GetCurrencySymbol = props =>
    (0)
      .toLocaleString(GetLocalCode(defaultLanguageCode), {
        style: 'currency',
        currency: pathOr(
          'EUR',
          ['row', 'original', 'price', 'currency', 'name'],
          props,
        ),
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();

  return React.useMemo(
    () => [
      {
        Header: () => <HeaderCell>Image</HeaderCell>,
        accessor: 'media.images[0].mediaFile.mediaPath',
        Cell: props => (
          <ImgCell
            isButton
            onClick={() => onRowClick(props.row.cells[1].value)}
          >
            <Img alt={props.cell.value} src={props.cell.value} />
          </ImgCell>
        ),
      },
      {
        Header: () => <HeaderCell>VIN</HeaderCell>,
        accessor: 'vin',
        Cell: props => (
          <CellWrapper isButton onClick={() => onRowClick(props.cell.value)}>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Model</HeaderCell>,
        accessor: 'model.name',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Variant</HeaderCell>,
        accessor: 'model.variant',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        id: 'odometer',
        Header: () => <HeaderCell>Odometer</HeaderCell>,
        accessor: 'odometer.value',
        Cell: props => {
          const { value, onChange, onBlur } = EditableCell(props);
          return (
            <CellWrapper>
              <StyledNumberInput
                name={'odometer'}
                type="text"
                value={value}
                onValueChange={e => onChange(e.value)}
                thousandSeparator
                decimalScale={2}
                allowNegative={false}
                onBlur={onBlur}
              />
            </CellWrapper>
          );
        },
      },
      {
        id: 'retailPrice',
        Header: () => <HeaderCell>Retail Price</HeaderCell>,
        accessor: 'price.retailPrice',
        Cell: props => {
          const { value, onChange, onBlur } = EditableCell(props);
          return (
            <CellWrapper>
              <StyledNumberInput
                name={'retailPrice'}
                type="text"
                value={value}
                onValueChange={e => onChange(e.value)}
                thousandSeparator
                decimalScale={2}
                allowNegative={false}
                prefix={GetCurrencySymbol(props)}
                onBlur={onBlur}
              />
            </CellWrapper>
          );
        },
      },
      {
        id: 'tradePrice',
        Header: () => <HeaderCell>Trade Price</HeaderCell>,
        accessor: 'price.tradePrice',
        Cell: props => {
          const { value, onChange, onBlur } = EditableCell(props);
          return (
            <CellWrapper>
              <StyledNumberInput
                name={'tradePrice'}
                type="text"
                value={value}
                onValueChange={e => onChange(e.value)}
                thousandSeparator
                decimalScale={2}
                allowNegative={false}
                prefix={GetCurrencySymbol(props)}
                onBlur={onBlur}
              />
            </CellWrapper>
          );
        },
      },
      {
        id: 'standInValue',
        Header: () => <HeaderCell>Stand in Price</HeaderCell>,
        accessor: 'price.standInValue',
        Cell: props => {
          const { value, onChange, onBlur } = EditableCell(props);
          return (
            <CellWrapper>
              <StyledNumberInput
                name={'standInValue'}
                type="text"
                value={value}
                onValueChange={e => onChange(e.value)}
                thousandSeparator
                decimalScale={2}
                allowNegative={false}
                prefix={GetCurrencySymbol(props)}
                onBlur={onBlur}
              />
            </CellWrapper>
          );
        },
      },
      {
        Header: () => <HeaderCell>Wheelbase</HeaderCell>,
        accessor: 'wheelBase',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Bodystyle</HeaderCell>,
        accessor: 'bodyStyle.bodyType',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Colour</HeaderCell>,
        accessor: 'colour.exteriorColour',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Year</HeaderCell>,
        accessor: 'regYear',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Reg. Date</HeaderCell>,
        accessor: 'regInitial',
        Cell: props => (
          <CellWrapper>
            <TextCell>{GetFormatedDate(props.cell.value)}</TextCell>
          </CellWrapper>
        ),
      },
      {
        id: 'media.images.length',
        Header: () => <HeaderCell>Photos</HeaderCell>,
        accessor: row =>
          row.media && row.media.images && row.media.images.length,
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        id: 'entryDate',
        Header: () => <HeaderCell>Days in Stock</HeaderCell>,
        accessor: row => row.entryDate,
        Cell: props => (
          <CellWrapper>
            <TextCell>
              {moment(new Date()).diff(props.cell.value, 'days')}
            </TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Retailer Name</HeaderCell>,
        accessor: 'dealer.name',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Status</HeaderCell>,
        accessor: 'type',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
      {
        Header: () => <HeaderCell>Visibility</HeaderCell>,
        accessor: 'visibility',
        Cell: props => (
          <CellWrapper>
            <TextCell>{props.cell.value}</TextCell>
          </CellWrapper>
        ),
      },
    ],
    [onRowClick, EditableCell],
  );
}
