// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as vehicleActions } from '../../state/actions/vehicles';
import ROUTES from '../../constants/routes';
import { actions as routerActions } from '../../state/actions/router';

type InventoryContainerHook = {
  masterData: Object[],
  vehicles: Object[],
  updateVehicle: Function,
  vehiclesCount: Number,
  pageTotal: Number,
};

const defaultSorting = {
  id: 'entryDate',
  sortingDirection: 'Desc',
};

export function useInventoryContainer(): InventoryContainerHook {
  const dispatch = useDispatch();

  const vehicles = useSelector(state => state.vehicles.vehicles);
  const vehiclesCount = useSelector(state => state.vehicles.vehiclesCount);
  const pageTotal = useSelector(state => state.vehicles.pageTotal);
  const pageNumber = useSelector(state => state.vehicles.pageNumber);
  const pageSize = useSelector(state => state.vehicles.pageTotal);

  const [searchText, setSearchText] = useState();
  const [sortParams, setSortParams] = useState(defaultSorting);

  const [loading, setLoading] = React.useState(false);
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    dispatch(vehicleActions.loadVehicles(pageNumber, sortParams, searchText));
  }, [searchText, sortParams, dispatch]);

  const onSearchTextUpdate = value => {
    setSearchText(value);
  };

  const onSearch = () => {
    dispatch(vehicleActions.loadVehicles(pageNumber, sortParams, searchText));
  };

  const fetchData = React.useCallback(({ pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    if (fetchId === fetchIdRef.current) {
      dispatch(vehicleActions.loadVehicles(pageIndex, sortParams, searchText));
      setLoading(false);
    }
  }, [dispatch, searchText, sortParams]);

  const sortPropertyMapper = {
    bodyStyle: 'bodyStyle.bodyType',
    odometer: 'odometer.value',
    dealer: 'dealer.name',
    model: 'model.name',
    tradePrice: 'price.tradePrice',
    standInValue: 'price.standInValue',
    variant: 'model.variant',
    retailPrice: 'price.retailPrice',
    colour: 'colour.exteriorColour',
  };

  const sortChangeHandler = (updatedSortParams: SortParams) => {
    const sortData =
      updatedSortParams.sortingDirection === 'NONE'
        ? defaultSorting
        : {
            id:
              sortPropertyMapper[updatedSortParams.id] || updatedSortParams.id,
            sortingDirection: updatedSortParams.sortingDirection,
          };

    setSortParams(sortData);
  };

  const submitSave = () => {
    dispatch(vehicleActions.updateVehicles());
  };

  const onRowClick = vin => {
    dispatch(routerActions.navigateTo(`${ROUTES.VEHICLE}${vin}`));
  };

  const navigateToAddVehicle = () => {
    dispatch(routerActions.navigateTo(ROUTES.VEHICLE));
  };

  const EditableCell = ({
    cell: { value: initialValue },
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue || '');

    const onChange = updatedValue => {
      updateMyData(index, id, updatedValue);
      setValue(updatedValue);
    };

    React.useEffect(() => {
      setValue(initialValue || '');
    }, [initialValue]);

    return { value, onChange };
  };

  const getSourceProperty = value => ({ source: 'manual', value });

  const handleOdometerBlur = (vehicle, value, key) => {
    const {
      id,
      makeId,
      vin,
      [key]: { value: odometer, units },
    } = vehicle;

    const batchVehicle = {
      id,
      makeId,
      vin,
      odometer: {
        value: getSourceProperty(value),
        units: getSourceProperty(units),
      },
    };
    dispatch(vehicleActions.updateBatchVehicles(batchVehicle));
  };

  const handlePriceBlur = (vehicle, value, key) => {
    const { id, makeId, vin } = vehicle;
    const batchVehicle = {
      id,
      makeId,
      vin,
      price: { [key]: getSourceProperty(value) },
    };
    dispatch(vehicleActions.updateBatchVehicles(batchVehicle));
  };

  const updateMyData = (rowIndex, columnId, value) => {
    const foundVehicle = vehicles[rowIndex];

    switch (columnId) {
      case 'odometer':
        handleOdometerBlur(foundVehicle, value, columnId);
        break;
      case 'retailPrice':
      case 'tradePrice':
      case 'standInValue':
        handlePriceBlur(foundVehicle, value, columnId);
        break;
      default:
        break;
    }
  };

  return {
    onSearch,
    onSearchTextUpdate,
    pageNumber,
    pageTotal,
    pageSize,
    searchText,
    sortChangeHandler,
    submitSave,
    vehicles,
    vehiclesCount,
    onRowClick,
    navigateToAddVehicle,
    fetchData,
    loading,
    updateMyData,
    EditableCell,
  };
}
