// @flow

export const constants = {
  loadVehicleDetails: 'LOAD_VEHICLE_DETAILS',
  loadVehicleDetailsSuccess: 'LOAD_VEHICLE_DETAILS_SUCCESS',
  loadVehicleDetailsFailure: 'LOAD_VEHICLE_DETAILS_FAILURE',
  loadMasterDataSuccess: 'MASTERDATA_LOAD_SUCCESS',
  loadMasterData: 'LOAD_MASTERDATA',
  loadMasterDataFailure: 'MASTERDATA_LOAD_FAILURE',
  loadMakeModelOptions: 'LOAD_MAKEMODELS',
  loadMakeModelOptionsSuccess: 'MAKEMODELS_LOAD_SUCCESS',
  loadMakeModelOptionsFailure: 'MAKEMODELS_LOAD_FAILURE',
  loadVehicle: 'LOAD_VEHICLE',
  loadVehicleFailure: 'LOAD_VEHICLE_FAILURE',
  loadVehicleSuccess: 'LOAD_VEHICLE_SUCCESS',
  saveVehicle: 'SAVE_VEHICLE',
  saveVehicleSuccess: 'SAVE_VEHICLE_SUCCESS',
  saveVehicleFailure: 'SAVE_VEHICLE_FAILURE',
};

export const actions = {
  loadVehicle: (id: string) => ({
    type: constants.loadVehicle,
    payload: id,
  }),
  loadVehicleFailure: error => ({
    type: constants.loadVehicleFailure,
    payload: error,
  }),
  loadVehicleSuccess: payload => ({
    type: constants.loadVehicleSuccess,
    payload,
  }),
  loadVehicleDetails: (addVehicle, vin) => ({
    type: constants.loadVehicleDetails,
    payload: { addVehicle, vin },
  }),
  loadVehicleDetailsFailure: error => ({
    type: constants.loadVehicleDetailsFailure,
    payload: error,
  }),
  loadMasterData: (makeId, modelId) => ({
    type: constants.loadMasterData,
    payload: { makeId, modelId },
  }),
  loadMasterDataSuccess: payload => ({
    type: constants.loadMasterDataSuccess,
    payload,
  }),
  loadMasterDataFailure: payload => ({
    type: constants.loadMasterDataFailure,
    payload,
  }),
  loadMakeModelOptions: makeId => ({
    type: constants.loadMakeModelOptions,
    payload: makeId,
  }),
  loadMakeModelOptionsSuccess: payload => ({
    type: constants.loadMakeModelOptionsSuccess,
    payload,
  }),
  loadMakeModelOptionsFailure: () => ({
    type: constants.loadMakeModelOptionsFailure,
  }),
  saveVehicle: (addVehicle, vehicle) => ({
    type: constants.saveVehicle,
    payload: { addVehicle, vehicle },
  }),
  saveVehicleFailure: error => ({
    type: constants.saveVehicleFailure,
    payload: error,
  }),
  saveVehicleSuccess: payload => ({
    type: constants.saveVehicleSuccess,
    payload,
  }),
  loadVehicleDetailsSuccess: () => ({
    type: constants.loadVehicleDetailsSuccess,
  }),
};
