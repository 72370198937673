import homeIcon from './navigation/Home.svg';
import inventory from './navigation/Inventory.svg';
import feedAudit from './navigation/Feeds.svg';
import leads from './navigation/Leads.svg';
import reports from './navigation/Reports.svg';
import profile from './navigation/Profile.svg';
import help from './navigation/Help.svg';
import logout from './navigation/Logout.svg';

export default {
  homeIcon,
  inventory,
  feedAudit,
  leads,
  reports,
  profile,
  help,
  logout,
};
