// @flow
import { constants, type Action } from '../actions/router';

export type State = {
  location: ?Location,
};

const initialState: State = {
  location: null,
};

export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.navigateToSuccess:
      return { ...state, location: action.payload };
    default:
      return state;
  }
}
