// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

const PaginationBlock = styled.div`
  display: inline-block;
  user-select: none;
`;

const PaginationPrevious = styled(PaginationBlock)`
  margin-right: 12px;
  cursor: pointer;
`;

const PaginationCenter = styled(PaginationBlock)`
  color: #2f3031;
  font-size: 12px;
  line-height: 17px;
`;

const PaginationRight = styled(PaginationBlock)`
  cursor: pointer;
  margin-left: 12px;
`;

const PaginationIcon = styled.div`
  border: solid #788896;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 8px;
  width: 8px;

  ${({ disabled }) =>
    disabled &&
    `
    border-color: #dbdbdb;
    cursor: default;
  `};
`;

const PaginationPreviousIcon = styled(PaginationIcon)`
  transform: rotate(-225deg);
`;

const PaginationNextIcon = styled(PaginationIcon)`
  transform: rotate(-45deg);
`;

const PaginationText = styled.span`
  margin: 0 5px;
`;

const PaginationJumpInput = styled.input`
  height: 30px;
  width: 36px;
  color: #175f5b;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #bdc7d3;
  border-radius: 2px;
`;

type Props = {
  pages: Number,
  pageNumber: Number,
  pageOptions: Object,
  pageCount: Number,
  gotoPage: Function,
  pageIndex: Number,
};

export default function(props: Props) {
  const {
    pages,
    pageNumber,
    pageCount,
    gotoPage,
    pageIndex,
  } = props;

  const canNextPage = pageIndex < pageCount;
  const canPreviousPage = pageIndex > 1;

  const [page, setPage] = useState(pageNumber);

  const applyPage = () => {
    if (!isNaN(page) && page > 0 && page <= pageCount) {
      gotoPage({ pageIndex: page });
    }
  };

  return (
    <PaginationBlock>
      <PaginationPrevious
        onClick={() => {
          if (!canPreviousPage) return;
          gotoPage({ pageIndex: pageIndex - 1 });
        }}
      >
        <PaginationPreviousIcon disabled={!canPreviousPage} />
      </PaginationPrevious>
      <PaginationCenter>
        <PaginationText> Page </PaginationText>
        <PaginationJumpInput
          type="number"
          value={page}
          onChange={e => setPage(e.target.value)}
          onKeyPress={e => {
            if (e.which === 13 || e.keyCode === 13) {
              applyPage(e);
            }
          }}
        />
        <PaginationText> of {pages}</PaginationText>
      </PaginationCenter>
      <PaginationRight
        onClick={event => {
          event.preventDefault();
          if (!canNextPage) return;
          gotoPage({ pageIndex: pageIndex + 1 });
        }}
      >
        <PaginationNextIcon disabled={!canNextPage} />
      </PaginationRight>
    </PaginationBlock>
  );
}
