// @flow
import React from 'react';
import styled from 'styled-components';

import { BackButtonHeader } from '..';
import img from '../../../assets/inventory_bg.jpg';

const Container = styled.div`
  display: flex;
  background-image: linear-gradient(
      0deg,
      #4e9af3 0%,
      transparent 100%
    ),
    url(${img});
  background-repeat: no-repeat;
  flex-shrink: 0;
  width: 100%;
  height: 65px;
`;

const Title = styled.div`
  padding-left: 15px;
  padding-top: 5px;
  color: #ffffff;
  font-size: 20px;
  width: 50%;
  font-weight: 600;
`;

const Row = styled.div`
  width: 100%;
  height: 35px;
  flex-wrap: nowrap;
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  children: any,
  title: string,
  backButtonRoute: string,
};

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-left: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 10px;
`;

export default function({ title, children, backButtonRoute }: Props) {
  return (
    <Container>
      {backButtonRoute && <BackButtonHeader toRoute={backButtonRoute} />}
      <Row>
        {title && <Title>{title}</Title>}
        {children}
      </Row>
    </Container>
  );
}
