// @flow
import React from 'react';
import styled, { css } from 'styled-components';

const FormFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  flex-direction: ${props => (props.direction ? 'row' : 'column')};
`;

const Label = styled.div`
  color: ${props => (props.error ? '#FF6767' : '#2F3031')};
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  min-width: 90px;
  width: 100%;
  margin-top: 8px;
  text-transform: uppercase;
  ${props =>
    props.required &&
    css`
      :after {
        content: ' *';
        color: #b91a2e;
        font-weight: bold;
      }
    `};
`;

const ErrorMessage = styled.div`
  color: #b91a2e;
  width: 100%;
  text-align: right;
  font-size: 14px;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 8px;
`;

const FieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
`;

type FormFieldProps = {
  label: string,
  required: boolean,
  error: string,
  children: any,
  direction: string,
};

export default function FormField({
  label,
  required,
  children,
  error,
  direction,
}: FormFieldProps) {
  return (
    <FormFieldContainer direction={direction}>
      <FieldHeader>
        {label && <Label required={required}>{label}</Label>}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </FieldHeader>
      {children}
    </FormFieldContainer>
  );
}
