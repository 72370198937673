import React from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import {
  SelectField,
  InputField,
  FormDatepicker,
  FormRadioButtons,
  NumberInput,
  ToggleButton,
} from '../Common';

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionContainer = styled.div`
  width: 100%;
  background-color: #efefef;
`;

const Column = styled.div`
  background-color: #efefef;
  flex-grow: 1;
  flex-basis: 0;
`;

const DetailsColumn = styled(Column)`
  padding: 15px 20px;
  :not(:first-child) {
    margin-left: 10px;
  }
`;

const ContainerTitle = styled.div`
  color: #293845;
  font-size: 18px;
  height: 58px;
  padding-left: 16px;
  font-weight: bold;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
`;

export default ({ props }) => {
  const {
    vehicle,
    register,
    updateVehicle,
    errors,
    setValue,
    displayDateFormat,
    masterData,
  } = props;

  const dataSource = { manual: 'Manual' };
  const requiredOption = { required: 'required' };

  const getColourOptions = key => {
    const colourGroup =
      (masterData.colours &&
        masterData.colours.find(obj => obj.label === key)) ||
      [];
    const colourOptions = (colourGroup && colourGroup.vehicleOptions) || [];

    return colourOptions.map(colour => ({
      value: colour.id,
      label: colour.label,
    }));
  };

  const minimumDate = Date.parse('1970-12-01');

  return (
    <SectionContainer>
      <ContainerTitle>General</ContainerTitle>
      <ColumnContainer>
        <DetailsColumn>
          <InputField
            name="vin"
            type="text"
            label="VIN Number"
            value={vehicle.vin || ''}
            keyValue="vin"
            error={errors.vin}
            reference={register({ required: 'required' })}
          />
          <InputField
            type="text"
            label="Reg. Number"
            value={pathOr('', ['regNo', 'value'], vehicle)}
            keyValue="regNo"
            name="regNo"
            error={errors.regNo}
            required
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                regNo: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('regNo', value);
            }}
          />
          <SelectField
            type="text"
            label="Status"
            keyValue="vehicleStatus"
            name="vehicleStatus"
            options={[
              { label: 'Available', value: 'Available' },
              { label: 'Sold', value: 'Sold' },
            ]}
            value={pathOr('Available', ['vehicleStatus', 'value'], vehicle)}
            onChange={({ value }) => {
              updateVehicle({
                ...vehicle,
                vehicleStatus: {
                  source: dataSource.manual,
                  value,
                },
              });
            }}
            required
          />
          <InputField
            type="text"
            label="Inspection Certificate Number"
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                inspectionCertificateNumber: { value },
              });
            }}
            value={pathOr(
              '',
              ['inspectionCertificateNumber', 'value'],
              vehicle,
            )}
            keyValue="inspectionCertificateNumber"
            name="inspectionCertificateNumber"
          />
          <FormDatepicker
            label="Registration"
            keyValue="registration"
            name="registration"
            dateFormat={displayDateFormat}
            value={pathOr('', ['regInitial', 'value'], vehicle)}
            onSelect={(_, value) => {
              updateVehicle({
                ...vehicle,
                regInitial: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('registration', value);
            }}
            error={errors.registration}
            minDate={minimumDate}
            maxDate={new Date()}
            required
          />
          <FormDatepicker
            label="Date in Stock"
            keyValue="dateInStock"
            name="dateInStock"
            dateFormat={displayDateFormat}
            value={pathOr('', ['entryDate', 'value'], vehicle)}
            onSelect={(_, value) => {
              updateVehicle({
                ...vehicle,
                entryDate: {
                  source: dataSource.manual,
                  value,
                },
              });
            }}
            minDate={minimumDate}
            maxDate={new Date()}
          />
          <FormRadioButtons
            label="Visibility"
            keyValue="hidden"
            options={[
              { description: 'Visible', value: false },
              { description: 'Hidden', value: true },
            ]}
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                hidden: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('Visible', value);
            }}
            value={pathOr('hidden', ['hidden', 'value'], vehicle)}
          />
          <FormRadioButtons
            label="Drive"
            name="wheelDrive"
            keyValue="wheelDrive"
            options={[
              { description: 'LHD', value: 'L' },
              { description: 'RHD', value: 'R' },
            ]}
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                wheelDrive: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('wheelDrive', value);
            }}
            value={pathOr('', ['wheelDrive', 'value'], vehicle)}
          />
          <FormRadioButtons
            label="Type"
            keyValue="type"
            options={[
              { description: 'New', value: 'New' },
              { description: 'Used', value: 'Used' },
            ]}
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                type: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('type', value);
            }}
            value={pathOr('New', ['type', 'value'], vehicle)}
          />
          <ToggleButton
            labelText="Approved"
            onChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                approved: {
                  source: dataSource.manual,
                  value,
                },
              });
            }}
            keyValue="approved"
            value={pathOr(false, ['approved', 'value'], vehicle)}
          />
        </DetailsColumn>
        <DetailsColumn>
          <InputField
            disabled
            type="text"
            label="Make"
            value={pathOr('', ['make', 'value'], vehicle)}
            error={errors.make}
            required
            name="make"
          />
          <InputField
            disabled
            type="text"
            label="Model"
            value={pathOr('', ['model', 'name', 'value'], vehicle)}
            error={errors.model}
            required
            name="model"
            reference={register(requiredOption)}
          />
          <SelectField
            keyValue="bodystyle"
            name="bodystyle"
            placeholder="Select Bodystyle..."
            onChange={e => {
              updateVehicle({
                ...vehicle,
                bodyStyle: {
                  ...vehicle.bodyStyle,
                  bodyType: {
                    id: e.value,
                    source: dataSource.manual,
                    label: e.label,
                  },
                },
              });
              setValue('bodyStyle', e.value);
            }}
            label="Bodystyle"
            error={errors.bodyStyle}
            value={pathOr('', ['bodyStyle', 'bodyType', 'id'], vehicle)}
            options={masterData.bodystyles}
            required
          />
          <SelectField
            keyValue="modelYear"
            name="modelYear"
            placeholder="Select Model Year..."
            onChange={({ value }) => {
              updateVehicle({
                ...vehicle,
                model: {
                  ...vehicle.model,
                  year: {
                    source: dataSource.manual,
                    value,
                  },
                },
              });
              setValue('modelYear', value);
            }}
            error={errors.modelYear}
            label="Model Year"
            value={pathOr('', ['model', 'year', 'value'], vehicle)}
            options={[
              { label: '2010', value: '2010' },
              { label: '2011', value: '2011' },
              { label: '2012', value: '2012' },
              { label: '2013', value: '2013' },
              { label: '2014', value: '2014' },
              { label: '2015', value: '2015' },
              { label: '2016', value: '2016' },
              { label: '2017', value: '2017' },
              { label: '2018', value: '2018' },
              { label: '2019', value: '2019' },
              { label: '2020', value: '2020' },
            ]}
            required
          />
          <NumberInput
            name="odometer"
            label="Odometer"
            keyValue="odometer"
            error={errors.odometer}
            value={pathOr('', ['odometer', 'value', 'value'], vehicle)}
            onValueChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                odometer: {
                  ...vehicle.odometer,
                  value: {
                    source: dataSource.manual,
                    value,
                  },
                },
              });
              setValue('odometer', value);
            }}
            required
          >
            <ToggleButton
              onChange={(_, value) => {
                updateVehicle({
                  ...vehicle,
                  odometer: {
                    ...vehicle.odometer,
                    units: {
                      source: dataSource.manual,
                      value: value ? 'Kilometers' : 'Miles',
                    },
                  },
                });
                setValue('unit', value ? 'Kilometers' : 'Miles');
              }}
              keyValue="odometerUnit"
              leftToggleLabel="KM"
              rightToggleLabel="MI"
              value={
                pathOr('Miles', ['odometer', 'units', 'value'], vehicle) !==
                'Miles'
              }
              name="odometerUnit"
              internal
            />
          </NumberInput>
          <SelectField
            keyValue="doors"
            name="doors"
            placeholder="Select Door Count..."
            onChange={({ value }) => {
              updateVehicle({
                ...vehicle,
                doors: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('doors', value);
            }}
            label="Doors"
            value={pathOr('', ['doors', 'value'], vehicle)}
            options={[
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
            ]}
            error={errors.doors}
            required
          />
          <NumberInput
            label="Seats"
            name="numberOfSeats"
            value={pathOr('', ['numberOfSeats', 'value'], vehicle)}
            onValueChange={(_, value) => {
              updateVehicle({
                ...vehicle,
                numberOfSeats: {
                  source: dataSource.manual,
                  value,
                },
              });
              setValue('numberOfSeats', value);
            }}
            error={errors.numberOfSeats}
            keyValue="numberOfSeats"
            allowNegative={false}
          />
        </DetailsColumn>
        <DetailsColumn>
          <InputField
            keyValue="variant"
            name="variant"
            label="Variant"
            value={pathOr('', ['model', 'variant', 'id'], vehicle)}
            options={masterData.variants}
            disabled
          />
          <InputField
            keyValue="engine"
            name="engine"
            placeholder="Select Engine..."
            label="Engine"
            value={pathOr('', ['engine', 'variant', 'value'], vehicle)}
            valueFromOption={o => o.id}
            options={masterData.engines}
            disabled
          />
          <SelectField
            keyValue="interiorColour"
            name="interiorColour"
            placeholder="Select Colour..."
            onChange={e => {
              updateVehicle({
                ...vehicle,
                colour: {
                  ...vehicle.colour,
                  interiorColour: {
                    id: e.value,
                    source: dataSource.manual,
                    label: e.label,
                  },
                },
              });
              setValue('interiorColour', e.value);
            }}
            error={errors.interiorColour}
            label="Interior Colour"
            value={pathOr('', ['colour', 'interiorColour', 'id'], vehicle)}
            options={getColourOptions('interior')}
            required
          />
          <SelectField
            keyValue="exteriorColour"
            name="exteriorColour"
            placeholder="Select Colour..."
            onChange={e => {
              updateVehicle({
                ...vehicle,
                colour: {
                  ...vehicle.colour,
                  exteriorColour: {
                    id: e.value,
                    source: dataSource.manual,
                    label: e.label,
                  },
                },
              });
              setValue('exteriorColour', e.value);
            }}
            error={errors.exteriorColour}
            label="Exterior Colour"
            value={pathOr('', ['colour', 'exteriorColour', 'id'], vehicle)}
            options={getColourOptions('exterior')}
            required
          />
          <SelectField
            keyValue="transmission"
            name="transmission"
            onChange={e => {
              updateVehicle({
                ...vehicle,
                transmission: {
                  ...vehicle.transmission,
                  transmission: {
                    id: e.value,
                    source: dataSource.manual,
                    label: e.label,
                  },
                },
              });
              setValue('transmission', e.value);
            }}
            error={errors.transmission}
            label="Transmission"
            value={pathOr('', ['transmission', 'id'], vehicle)}
            options={masterData.transmissions}
            required
          />
          <SelectField
            keyValue="fuel"
            name="fuel"
            placeholder="Select Fuel Type..."
            onChange={e => {
              updateVehicle({
                ...vehicle,
                engine: {
                  ...vehicle.engine,
                  fuel: {
                    id: e.value,
                    source: dataSource.manual,
                    label: e.label,
                  },
                },
              });
              setValue('fuel', e.value);
            }}
            error={errors.fuel}
            label="Fuel"
            value={pathOr('', ['engine', 'fuel', 'type', 'id'], vehicle)}
            options={masterData.fuelTypes}
            required
          />
        </DetailsColumn>
      </ColumnContainer>
    </SectionContainer>
  );
};
