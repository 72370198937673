// @flow
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { FormField, ClickPropagationBlocker } from '.';

const styledInputFactory = input => styled(input)`
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#d8d8d8')};
  background-color: #ffffff;
  height: 36px;
  width: 100%;
  outline: none;
  box-shadow: none;
  position: relative;
  display: block;
`;

const Block = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const ChildComponentHolder = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
`;

const StyledNumberInput = styledInputFactory(NumberFormat);

type Props = {
  label: string,
  required: boolean,
  keyValue: string,
  value: string,
  onValueChange: (keyValue: string, value: string) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  currencyPrefix: string,
  allowNegative: boolean,
  prefix: String,
};

const getValue = (value: string) => {
  if (typeof value === 'object') {
    return '0';
  }

  return value;
};

export default function NumberInput({
  label,
  required,
  value,
  onValueChange,
  onKeyPress,
  error,
  disabled,
  placeholder,
  reference,
  name,
  currencyPrefix = '',
  allowNegative = false,
  children,
}: Props) {
  return (
    <FormField label={label} required={required} error={error}>
      <Block>
        <ClickPropagationBlocker>
          <StyledNumberInput
            name={name}
            type="text"
            value={getValue(value)}
            onKeyPress={onKeyPress}
            onValueChange={e => onValueChange(e, e.value)}
            disabled={disabled}
            placeholder={placeholder}
            error={error}
            ref={reference}
            thousandSeparator
            decimalScale={2}
            allowNegative={allowNegative}
            prefix={currencyPrefix}
          />
          <ChildComponentHolder>{children}</ChildComponentHolder>
        </ClickPropagationBlocker>
      </Block>
    </FormField>
  );
}

NumberInput.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: () => {},
  onKeyPress: () => {},
  label: '',
  error: '',
  validate: false,
};
