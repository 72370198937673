/* this may change to /app in prod */
const ROOT = '/';
const INDEX = ROOT;
const INVENTORY = '/inventory';
const FEEDAUDIT = '/feed-audit';
const LEADS = '/leads';
const REPORTING = '/reporting';
const MYACCOUNT = '/my-account';
const HELP = '/help';
const VEHICLEVIEW = '/vehicle/:vin?';
const VEHICLE = '/vehicle/';

const ROUTES = {
  INDEX,
  INVENTORY,
  FEEDAUDIT,
  LEADS,
  REPORTING,
  MYACCOUNT,
  HELP,
  VEHICLEVIEW,
  VEHICLE,
};

export default ROUTES;
