// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import img from '../../assets/navigation_back.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 20px;
  height: 20px;
  &:hover {
    background-color: rgba(255,255,255,0.2);
  }
`;

type Props = {
  toRoute: string;
}

export default function (props: Props) {
    const { toRoute } = props;
    return (
      <Container>
        <StyledLink to={toRoute}>
          <img src={img} alt="Back" />
        </StyledLink>
      </Container>
    );
}
