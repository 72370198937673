import React, { useState } from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import settings from '../../settings';
import MediaUploadStylesheet from './MediaUploadStylesheet';
import cross from '../../assets/cross_icn.svg';
import { PrimaryButton, SecondaryButton, CloudinaryMediaUpload } from '../Common';

const ContainerTitle = styled.div`
  color: #293845;
  font-size: 18px;
  height: 58px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: bold;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
`;

const TitledFormContainerImages = styled.div`
  background-color: #efefef;
  z-index: -1;
  padding: 10px 10px 20px 20px;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  padding: 5px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  flex-basis: 31.33%;
  margin: 10px 1% 0 0;
`;

const ImagePreviewWrapper = styled.div`
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  position: relative;
  height: 280px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const ImageContainerTitle = styled.div`
  color: #2f3031;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 10px;
`;

const ImageDeleteButton = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 4px;
  top: 4px;
  background-color: #ffffff;
  height: 20px;
  width: 20px;
  justify-content: center;
`;

export default ({ props }) => {
  const { vehicle, updateVehicle } = props;
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const deleteImage = index => {
    const currentImages = vehicle.media.images;

    currentImages.splice(index, 1);

    const updated = { ...vehicle, media: { images: currentImages } };

    updateVehicle(updated);
  };

  function onImageUpload(uploadedImages) {
    updateVehicle(vehicle => ({
      ...vehicle,
      media: {
        images: [
          ...pathOr([], ['media', 'images'], vehicle),
          ...uploadedImages.map(image => ({
            mediaFile: {
              mediaPath: {
                value: image.secure_url,
                source: 'Manual',
              },
            },
          })),
        ],
      },
    }));
  }

  return (
    <>
      <ContainerTitle>
        <div>Images</div>
        {!isUploadOpen ? (
          <PrimaryButton type="button" onClick={() => setIsUploadOpen(true)}>
            Add images
          </PrimaryButton>
        ) : (
          <SecondaryButton type="button" onClick={() => setIsUploadOpen(false)}>
            Close
          </SecondaryButton>
        )}
      </ContainerTitle>
      <TitledFormContainerImages>
        <CloudinaryMediaUpload
          accountName={settings.mediaAccountName}
          preset={settings.mediaUploadPreset}
          stylesheet={MediaUploadStylesheet}
          dropFileText="Drag and drop images here or"
          buttonText="Select..."
          openUploadWidget={isUploadOpen}
          onUploadMediaSuccess={onImageUpload}
        />
        <ImagesWrapper>
          {vehicle.media &&
            vehicle.media.images.map((image, index) => (
              <ImageContainer key={index}>
                <ImagePreviewWrapper url={image.mediaFile.mediaPath.value}>
                  <ImageDeleteButton
                    type="button"
                    onClick={() => deleteImage(index)}
                  >
                    <img src={cross} alt="remove" />
                  </ImageDeleteButton>
                </ImagePreviewWrapper>
                <ImageContainerTitle># {index + 1}</ImageContainerTitle>
              </ImageContainer>
            ))}
        </ImagesWrapper>
      </TitledFormContainerImages>
    </>
  );
};
