import styled from 'styled-components';

const Padding = styled.div`
    padding-left: ${props => props.left || 0};
    padding-right: ${props => props.right || 0};
    padding-top: ${props => props.top || 0};
    padding-bottom: ${props => props.bottom || 0};
`;

const Margin = styled.div`
    margin-left: ${props => props.left || 0};
    margin-right: ${props => props.right || 0};
    margin-top: ${props => props.top || 0};
    margin-bottom: ${props => props.bottom || 0};
`;

export { Padding, Margin };
