// @flow

export const constants = {
  loadVehicles: 'LOAD_VEHICLES',
  loadVehiclesSuccess: 'LOAD_VEHICLES_SUCCESS',
  loadVehiclesFailure: 'LOAD_VEHICLES_FAILURE',
  updateVehicles: 'UPDATE_VEHICLES',
  updateVehiclesFailure: 'UPDATE_VEHICLES_FAILURE',
  updateVehiclesSuccess: 'UPDATE_VEHICLES_SUCCESS',
  updateGridVehicles: 'UPDATE_GRID_VEHICLES',
  updateBatchVehicles: 'UPDATE_BATCH_VEHICLES',
};

export const actions = {
  loadVehicles: (pageNumber, sortParams, searchText) => ({
    type: constants.loadVehicles,
    payload: { pageNumber, sortParams, searchText },
  }),
  loadVehiclesFailure: error => ({
    type: constants.loadVehiclesFailure,
    payload: error,
  }),
  loadVehiclesSuccess: payload => ({
    type: constants.loadVehiclesSuccess,
    payload,
  }),
  updateVehicles: vehicle => ({
    type: constants.updateVehicles,
    payload: vehicle,
  }),
  updateVehiclesSuccess: () => ({
    type: constants.updateVehiclesSuccess,
  }),
  updateVehiclesFailure: error => ({
    type: constants.updateVehiclesFailure,
    payload: error,
  }),
  updateGridVehicles: vehicle => ({
    type: constants.updateGridVehicles,
    payload: vehicle,
  }),
  updateBatchVehicles: vehicle => ({
    type: constants.updateBatchVehicles,
    payload: vehicle,
  }),
};
