// @flow
import React from 'react';
import styled from 'styled-components';
import { FormField } from '.';

const Container = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckboxWrapper = styled.div`
  background-color: white;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 1px;
  height: 21px;
  width: 21px;
  box-sizing: border-box;
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.checked ? '#175F5B' : 'white'};
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 3px;
`;

const Checkmark = styled.span`
  width: 4px;
  height: 8px;
  background-color: transparent;
  border: solid ${props => props.checked ? 'white' : 'transparent'};
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -1px;
}
`;

type Props = {
  label: string;
  value: boolean;
  onChange: Function;
  required: boolean;
  keyValue: string;
}

export default (props: Props) => {
  const { label, value, onChange, required, keyValue } = props;
  return (
    <Container>
      <InputWrapper>
        <FormField label={label} required={required}>
          <CheckboxWrapper>
            <Checkbox checked={value} onClick={() => onChange(keyValue, !value)}>
              <Checkmark checked={value} />
            </Checkbox>
          </CheckboxWrapper>
        </FormField>
      </InputWrapper>
    </Container>
  );
};
