/* eslint-env browser */

const appSettings = window.AppSettings || {};
const authOn = appSettings.authOn === undefined || appSettings.authOn;
const redirectUri = appSettings.redirectUri || 'https://ims-frontend.dev.mtkip.com//';
const domain = process.env.REACT_APP_DOMAIN || 'connect-auto-ims.eu.auth0.com';
const clientId =
  process.env.REACT_APP_CLIENT_ID || 'k2jsSwLsWj05NAaXkZUwqXwp0FdlXllm';
const apiAudience =
  process.env.REACT_APP_API_AUDIENCE || 'https://connect-auto/management';
const authorisationScopes =
  process.env.REACT_APP_AUTHORISATION_SCOPES || 'openid';
const mediaAccountName =
  process.env.REACT_APP_MEDIA_ACCOUNT_NAME || 'motortrak';
const mediaBase =
  process.env.REACT_APP_MEDIA_BASE || 'https://res.cloudinary.com';
const mediaUploadPreset =
  process.env.REACT_APP_MEDIA_UPLOAD_PRESET || 'imsdealervehicleuploadpreset';
const awsRegion = appSettings.awsRegion || 'eu-west-1';

const settings = {
  authOn,
  redirectUri,
  domain,
  clientId,
  apiAudience,
  authorisationScopes,
  mediaAccountName,
  mediaBase,
  mediaUploadPreset,
  awsRegion,
};

export const {
  imsVehiclesUrl,
  masterDataUrl,
  vinLookupUrl,
  pageSize = 10,
  defaultLanguageCode,
} = appSettings;

export default settings;
