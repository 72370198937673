import { call, put, takeLatest, take, all } from 'redux-saga/effects';
import { get, put as update, post } from '../../helpers/http';
import { actions, constants } from '../actions/masterData';
import { actions as routerActions } from '../actions/router';
import {
  masterDataUrl,
  imsVehiclesUrl,
  vinLookupUrl,
  defaultLanguageCode,
} from '../../settings';
import ROUTES from '../../constants/routes';

function* loadMasterData({ payload }) {
  const url = `${masterDataUrl}make/${payload.makeId}/model/${payload.modelId}/options/language/${defaultLanguageCode}`;
  try {
    const response = yield call(get, {
      url,
      token: '',
    });
    yield put(actions.loadMasterDataSuccess(response.data));
  } catch (error) {
    yield put(actions.loadMasterDataFailure({ message: error.message }));
  }
}

function* loadModelMaster({ payload }) {
  const url = `${masterDataUrl}make/${payload.makeId}/models/language/${defaultLanguageCode}`;
  try {
    const response = yield call(get, { url, token: '' });
    yield put(actions.loadMakeModelOptionsSuccess(response.data));
  } catch (error) {
    yield put(actions.loadMakeModelOptionsFailure({ message: error.message }));
  }
}

function* loadVehicle({ payload }) {
  try {
    let data = null;
    let url = `${imsVehiclesUrl}enhanced-vehicles/${payload.vin}`;
    ({ data } = yield call(get, { url }));
    if (data && payload.addVehicle) {
      throw new Error('Vehicle already exists.');
    }
    if (!data && payload.addVehicle) {
      url = `${vinLookupUrl}vehicles/ids/${payload.vin}`;
      ({ data } = yield call(get, { url }));
    }
    yield put(actions.loadVehicleSuccess(data));

    return data;
  } catch (error) {
    const errorMessage =
      error.response && error.response.status === 404
        ? 'Vehicle not found'
        : error.message;
    yield put(actions.loadVehicleFailure({ message: errorMessage }));
    throw error;
  }
}

function* loadVehicleDetails(action) {
  const { addVehicle, vin } = action.payload;
  try {
    yield put(actions.loadVehicle());
    if (vin) {
      const response = yield* loadVehicle({ payload: { vin, addVehicle } });

      yield all([
        loadMasterData({
          payload: { makeId: response.makeId, modelId: response.model.name.id },
        }),
        loadModelMaster({ payload: { makeId: response.makeId } }),
      ]);
    }
    take(actions.loadMakeModelOptionsSuccess);
    take(actions.loadMasterDataSuccess);
    yield put(actions.loadVehicleDetailsSuccess());
  } catch (error) {
    yield put(actions.loadVehicleDetailsFailure({ message: error.message }));
  }
}

function* saveVehicle(action) {
  const { addVehicle, vehicle } = action.payload;

  const url = addVehicle
    ? `${imsVehiclesUrl}enhanced-vehicles`
    : `${imsVehiclesUrl}enhanced-vehicles/${vehicle.vin}`;

  try {
    yield call(addVehicle ? post : update, {
      url,
      token: '',
      data: vehicle,
    });

    yield put(actions.saveVehicleSuccess(vehicle));
    if (addVehicle) {
      yield put(routerActions.navigateTo(`${ROUTES.VEHICLE}${vehicle.vin}`));
    }
  } catch (error) {
    yield put(actions.saveVehicleFailure(error.message));
  }
}

export default function* masterDataSaga() {
  yield takeLatest(constants.loadVehicleDetails, loadVehicleDetails);
  yield takeLatest(constants.loadMasterData, loadMasterData);
  yield takeLatest(constants.loadMakeModelOptions, loadModelMaster);
  yield takeLatest(constants.saveVehicle, saveVehicle);
}
