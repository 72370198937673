import styled from 'styled-components';

const RoundInput = styled.div`
  border: 2px solid;
  background-color: #FFFFFF;
  border-color: #9EADBA;
  /* border-color: ${props => props.checked ? '#9EADBA' : '#ccc'}; */
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: block;
  &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-top: 2px;
      background: ${props => props.checked ? '#007AFF' : '#FFFFFF'};
    }
`;

export default RoundInput;
