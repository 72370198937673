export const constants = {
  navigateTo: 'ROUTER_NAVIGATE_TO',
  navigateToSuccess: 'ROUTER_NAVIGATE_TO_SUCCESS',
};

export const actions = {
  navigateTo: path => ({
    type: constants.navigateTo,
    payload: path,
  }),
  navigateToSuccess: path => ({
    type: constants.navigateToSuccess,
    payload: path,
  }),
};
