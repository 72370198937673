import React from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { NumberInput } from '../Common';

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  background-color: #efefef;
  flex-grow: 1;
  flex-basis: 0;
`;

const PricingColumn = styled(Column)`
  :first-child {
    margin-right: 10px;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  background-color: #efefef;
`;

const ContainerTitle = styled.div`
  color: #293845;
  font-size: 18px;
  height: 58px;
  padding-left: 16px;
  font-weight: bold;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
`;

const ContainerFields = styled.div`
  padding: 15px 20px;
`;

export default ({ props }) => {
  const { vehicle, updateVehicle, errors, setValue } = props;

  const dataSource = { manual: 'Manual' };

  const GetCurrencySymbol = (locale, currency) =>
    (0)
      .toLocaleString(locale.replace('_', '-'), {
        style: 'currency',
        currency: currency || 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();

  const currencyCymbol = GetCurrencySymbol(
    'en_GB',
    pathOr('GBP', ['price', 'currency', 'name', 'value'], vehicle),
  );

  return (
    <SectionContainer>
      <ContainerTitle>Pricing</ContainerTitle>
      <ContainerFields>
        <ColumnContainer>
          <PricingColumn>
            <NumberInput
              name="retailPrice"
              label="Retail"
              keyValue="retailPrice"
              onValueChange={(_, value) => {
                updateVehicle({
                  ...vehicle,
                  price: {
                    ...vehicle.price,
                    retailPrice: {
                      source: dataSource.manual,
                      value,
                    },
                  },
                });
                setValue('retailPrice', value);
              }}
              error={errors.retailPrice}
              value={pathOr(0, ['price', 'retailPrice', 'value'], vehicle)}
              currencyPrefix={currencyCymbol}
              allowNegative={false}
              required
            />
            <NumberInput
              name="tradePrice"
              label="Trade"
              value={pathOr('', ['price', 'tradePrice', 'value'], vehicle)}
              keyValue="tradePrice"
              onValueChange={(key, value) => {
                updateVehicle({
                  ...vehicle,
                  price: {
                    ...vehicle.price,
                    tradePrice: {
                      source: dataSource.manual,
                      value,
                    },
                  },
                });
                setValue('tradePrice', value);
              }}
              currencyPrefix={currencyCymbol}
              error={errors.tradePrice}
              allowNegative={false}
              required
            />
          </PricingColumn>
          <PricingColumn>
            <NumberInput
              name="standInValue"
              label="Stand-in Value"
              keyValue="standInValue"
              onValueChange={(_, value) => {
                updateVehicle({
                  ...vehicle,
                  price: {
                    ...vehicle.price,
                    standInValue: {
                      source: dataSource.manual,
                      value,
                    },
                  },
                });
                setValue('standInValue', value);
              }}
              error={errors.standInValue}
              currencyPrefix={currencyCymbol}
              allowNegative={false}
              value={pathOr('', ['price', 'standInValue', 'value'], vehicle)}
              required
            />
            <NumberInput
              name="preparationCost"
              label="Preparation Cost"
              keyValue="preparationCost"
              onValueChange={(_, value) => {
                updateVehicle({
                  ...vehicle,
                  price: {
                    ...vehicle.price,
                    preparationCost: {
                      source: dataSource.manual,
                      value,
                    },
                  },
                });
                setValue('preparationCost', value);
              }}
              error={errors.preparationCost}
              currencyPrefix={currencyCymbol}
              allowNegative={false}
              value={pathOr('', ['price', 'preparationCost', 'value'], vehicle)}
              required
            />
          </PricingColumn>
        </ColumnContainer>
      </ContainerFields>
    </SectionContainer>
  );
};
