// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logo = styled.img.withConfig({ displayName: 'Logo' })`
  height: 36px;
  width: 141px;
  margin: 0 8px;
`;

const Container = styled.div`
  display: flex;
  min-width: 160px;
  height: 100%;
  background-color: #3c4e5e;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex-shink: 0;
`;

const NavigationContentContainer = styled.div`
  padding: 8px 0;
`;

const Divider = styled.hr.withConfig({
  displayName: 'Divider',
})`
  display: block;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.25);
`;

const StyledLink = styled(Link)`
  padding: 16px 0 16px 8px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  ${props => props.selected && 'background-color: #21313F;'};
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  font-family: 'Fira Sans';
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  padding-right: 9.5px;
`;

type LinkProps = {
  to: string,
  text: string,
  logo: string,
};

type LinkGroup = {
  links: LinkProps[],
};

type Props = {
  logo: string,
  linkGroups: LinkGroup[],
  pathname: string,
};

function renderLink(link: LinkProps, idx: number, currentPathName: string) {
  const { to, logo, text } = link;
  const selected = to === currentPathName;
  return (
    <StyledLink to={to || ''} key={idx} selected={selected}>
      <Icon src={logo} />
      <Title>{text}</Title>
    </StyledLink>
  );
}

function renderLinkGroup(
  linkGroup: LinkGroup,
  idx: number,
  currentPathName: string,
  totalGroups: number,
) {
  return (
    <div key={idx}>
      <NavigationContentContainer>
        {linkGroup.links.map((link, groupIdx) =>
          renderLink(link, groupIdx, currentPathName),
        )}
      </NavigationContentContainer>
      {idx < totalGroups - 1 ? <Divider /> : ''}
    </div>
  );
}

export default function(props: Props) {
  const { logo, linkGroups, pathname } = props;
  return (
    <Container>
      <NavigationContentContainer>
        <Logo src={logo} />
      </NavigationContentContainer>
      {linkGroups &&
        linkGroups.map((linkGroup, idx) =>
          renderLinkGroup(linkGroup, idx, pathname, linkGroups.length),
        )}
    </Container>
  );
}
