// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export default function App({ children }: { children: Node }) {
  return <Container>{children}</Container>;
}
