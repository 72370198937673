// @flow
import React from 'react';
import styled from 'styled-components';

import { FormField } from '.';

const Input = styled.input`
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.error ? '#FF6767' : props.disabled ? '#BBCAD6' : '#d8d8d8'};
  background-color: ${props =>
    props.disabled ? 'rgba(187,202,214,0.2)' : '#ffffff'};
  height: 36px;
  outline: none;
  box-shadow: none;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  keyValue: string,
  value: string,
  onChange: (keyValue: string, value: string) => void,
  onKeyPress: (event: Object) => void,
  error?: string,
  disabled: boolean,
  placeholder: string,
  translations: Object,
  validate?: boolean,
  truncateChars: boolean,
  reference: any,
  name: string,
  readonly: Boolean,
};

export default function InputField({
  label,
  required,
  type,
  keyValue,
  value,
  onChange,
  error,
  disabled,
  placeholder,
  truncateChars = false,
  reference,
  name,
}: Props) {
  return (
    <FormField label={label} required={required} error={error}>
      <Row>
        <Input
          type={type}
          value={value}
          onChange={e => onChange(keyValue, e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          truncateChars={truncateChars}
          ref={reference}
          name={name}
          error={error}
        />
      </Row>
    </FormField>
  );
}
InputField.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  onChange: () => {},
  onKeyPress: () => {},
  label: '',
  error: '',
  validate: false,
};
