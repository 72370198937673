import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Header, PrimaryButton, SecondaryButton } from '../Common';

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-left: 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 10px;
`;

export default function InventoryHeader({ onNavClick, onSubmitClick }) {
  const batchVehicles = useSelector(state => state.vehicles.batchVehicles);

  return (
    <Header title="Inventory">
      <ButtonsWrapper>
        <ButtonContainer>
          <PrimaryButton>Import...</PrimaryButton>
        </ButtonContainer>
        <ButtonContainer>
          <SecondaryButton onClick={() => onNavClick()}>
            Add New Vehicle
          </SecondaryButton>
        </ButtonContainer>
        <ButtonContainer>
          <PrimaryButton
            onClick={() => onSubmitClick()}
            disabled={!batchVehicles.length}
          >
            Save
          </PrimaryButton>
        </ButtonContainer>
      </ButtonsWrapper>
    </Header>
  );
}
