// @flow
import React from 'react';
import styled from 'styled-components';

import { displayError, InputLabel } from '.';

const Container = styled.div`
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 0 9px;
  box-sizing: border-box;
  border: 1px solid ${props => (displayError(props) ? '#B91A2E' : '#DBDBDB')};
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  background-color: #ffffff;
  height: 30px;
  width: 100%;
  outline: none;
  box-shadow: none;
`;

type Props = {
  label: string,
  name: string,
  value: string,
  onBlur: Function,
  onChange: Function,
  onDragStart: Function,
  onDrop: Function,
  onFocus: Function,
};

export default function (props: Props) {
  const {
    meta: { error },
  } = props;
  return (
    <Container>
      <InputLabel bottom={8} error={error} {...props} />
      <Input {...props} {...props.input} {...props.meta} />
    </Container>
  );
}
