// @flow
import React from 'react';
import styled from 'styled-components';

const displayError = ({ touched, error }) => touched && error;

const Label = styled.label`
  color: ${props => (displayError(props.meta) ? '#B91A2E' : '#777777')};
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  min-width: 90px;
  margin-bottom: ${props => `${props.bottom || 0}px`};
  ${({ required }) =>
  required &&
    `
    &:after {
      content: ' *';
      color: #b91a2e;
      font-weight: bold;
    }
  `}
`;

const InputError = styled.label`
  display: ${props => (displayError(props.meta) ? 'inline-block' : 'none')};
  width: ${props => props.width || 'auto'};
  text-align: right;
  flex-grow: 1;
  color: #b91a2e;
  font-size: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type Props = {
  bottom: number,
  label: string,
  error: string,
};

const InputLabel = (props: Props) => {
  const { bottom, label, error } = props;
  return (
    <LabelContainer>
      <Label bottom={bottom} {...props}>
        {label}
      </Label>
      <InputError {...props}>{error}</InputError>
    </LabelContainer>
  );
};

export { displayError, InputLabel, Label, InputError };
