/* eslint-env browser */
// @flow
import React from 'react';
import styled from 'styled-components';
import { useInventoryContainer } from './useInventoryView';
import { Expander, InputFieldSearch } from '../Common';
import searchIcon from '../../assets/search-icn.svg';
import Table from './InventoryTable';
import TableConfiguration from './TableConfiguration';
import InventoryHeader from './InventoryHeader';

const Container = styled.div`
  display: grid;
`;

export const InventoryContainer = () => {
  const {
    onSearch,
    onSearchTextUpdate,
    pageNumber,
    pageTotal,
    searchText,
    submitSave,
    vehicles,
    vehiclesCount,
    navigateToAddVehicle,
    fetchData,
    loading,
    updateMyData,
    onRowClick,
    EditableCell,
  } = useInventoryContainer();

  const ExpanderContainer = styled.div`
    height: 40px;
  `;

  const SearchContainer = styled.div`
    background-color: #f3f3f3;
    padding: 11px 15px 11px 13px;
  `;

  const InputFieldSearchContainer = styled.div`
    width: 50%;
  `;

  const Row = styled.div`
    display: flex;
    justify-content: flex-start;
  `;

  const FlexContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    height: 100%;
  `;

  const FlexItem = styled.div`
    width:100%;
    flex: 0 0 auto;
  `;

  return (
    <Container>
      <InventoryHeader
        onNavClick={navigateToAddVehicle}
        onSubmitClick={submitSave}
      />
      <SearchContainer>
        <Row>
          <ExpanderContainer>
            <Expander title="FILTERS" />
          </ExpanderContainer>
          <InputFieldSearchContainer>
            <InputFieldSearch
              keyValue="vin"
              searchText={searchText}
              onSearch={onSearch}
              onSearchTextUpdate={onSearchTextUpdate}
              icon={searchIcon}
              placeholder="Search..."
              buttonText="Search"
            />
          </InputFieldSearchContainer>
        </Row>
      </SearchContainer>
      <FlexContainer>
        <FlexItem>
          <Table
            columns={TableConfiguration(onRowClick, EditableCell)}
            data={vehicles}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageTotal}
            pageNumber={pageNumber}
            vehiclesCount={vehiclesCount}
            updateMyData={updateMyData}
          />
        </FlexItem>
      </FlexContainer>
    </Container>
  );
};
