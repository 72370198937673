// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import arrow from '../../assets/arrow-down-icn.svg';

import { FormField } from '.';

const Placeholder = styled.option`
  color: #293845;
  font-weight: 600;
`;

const Select = styled.select`
  background-image: url(${arrow});
  padding: 0.5em 3.5em 0.5em 1em;
  appearance: none;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${props => (props.error ? '#9e1b32' : '#bdc7d3')};
  border-radius: 0 2px 2px 0;	
  height: 36px;
  background-position:
    calc(100% - 13px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
  background-size:
    7px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
  outline: none;
  box-shadow: none;
  line-height: 16px;
  color: inherit;
  text-transform: inherit;
  letter-spacing: 0.5px;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
`;

type Props<Option> = {
  keyValue: string,
  value: Option,
  onChange: (input: { keyValue: string, value: any }) => void,
  disabled: boolean,
  options: Option[],
  labelFromOption?: Function,
  valueFromOption?: Function,
  label: string,
  required: boolean,
  children: Node,
  error?: string,
  value: string,
  placeholder?: string,
  reference: any,
  name: string,
};

export default function SelectField<Option>({
  label,
  required,
  keyValue,
  onChange,
  disabled,
  options = [],
  valueFromOption,
  labelFromOption,
  error,
  value = '',
  placeholder,
  reference,
  name,
}: Props<Option>) {
  return (
    <FormField label={label} required={required} error={error}>
      <Select
        name={name}
        ref={reference}
        onChange={({ target: { value: val, options: op, selectedIndex } }) => {
          const optionLabel = op[selectedIndex].innerText;
          onChange({ keyValue, value: val, label: optionLabel });
        }}
        disabled={disabled}
        error={error}
        value={value}
      >
        {[
          !value && placeholder && (
            <Placeholder key={`${placeholder}`} value={placeholder}>
              {placeholder}
            </Placeholder>
          ),
          ...options
            .map(o => {
              const optionLabel = labelFromOption(o);
              const optionValue = valueFromOption(o);
              return (
                <option key={`${optionValue}${optionLabel}`} value={optionValue}>
                  {optionLabel}
                </option>
              );
            }),
        ].filter(Boolean)}
      </Select>
    </FormField>
  );
}

SelectField.defaultProps = {
  labelFromOption: opt => opt.label,
  valueFromOption: opt => opt.value,
  error: '',
};
