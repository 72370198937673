/* eslint-disable import/no-webpack-loader-syntax */
// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NavigationPanel from '../components/NavigationPanel/NavigationPanel';
import NavigationIcons from '../assets/NavigationIcons';
import ROUTES from '../constants/routes';

import logo from '-!url-loader!../assets/Barline.svg';

type SidebarProps = {
  router: Object,
};

const mapStateToProps = (props: SidebarProps) => ({
  logo,
  pathname: props.router.location.pathname,
  linkGroups: [
    {
      links: [
        { text: 'Dealership Home', logo: NavigationIcons.homeIcon, to: ROUTES.INDEX },
        { text: 'Inventory', logo: NavigationIcons.inventory, to: ROUTES.INVENTORY },
        { text: 'Feed Audit', logo: NavigationIcons.feedAudit, to: ROUTES.FEEDAUDIT },
        { text: 'Leads', logo: NavigationIcons.leads, to: ROUTES.LEADS },
        { text: 'Reporting', logo: NavigationIcons.reports, to: ROUTES.REPORTING }
      ]
    },
    {
      links: [
        { text: 'My Account', logo: NavigationIcons.profile, to: ROUTES.MYACCOUNT },
        { text: 'Help', logo: NavigationIcons.help, to: ROUTES.HELP },
        { text: 'Log Out', logo: NavigationIcons.logout }
      ]
    }]
});

export default withRouter(connect(mapStateToProps)(NavigationPanel));
