// @flow
import React from 'react';
import styled from 'styled-components';
import InventoryPagination from './InventoryPagination';
import { SelectField } from '../Common';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 3px 0;
  padding: 11px 15px 11px 13px;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  color: #2f3031;
  font-size: 12px;
`;

const ResultCount = styled.div`
  line-height: 17px;
  padding: 0 16px;
`;

const FilterControlsContainer = styled.div`
  display: flex;
  color: #293845;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
`;

const PaginationWrapper = styled.div`
  position: absolute;
  right: 0;
  padding-right: 20px;
`;

type Props = {
  canPreviousPage: Boolean,
  canNextPage: Boolean,
  pageOptions: Object,
  pageCount: int,
  gotoPage: Function,
  nextPage: Function,
  previousPage: Function,
  setPageSize: Function,
  pageIndex: int,
  pageSize: int,
  vehiclesCount: Number,
};

export default function(props: Props) {
  const { pageOptions, pageCount, gotoPage, pageIndex, vehiclesCount } = props;

  return (
    <Container>
      <FilterContainer>
        <ResultCount>{`${vehiclesCount} Results sorted by`}</ResultCount>
        <FilterControlsContainer>
          <SelectField
            keyValue="order"
            name="order"
            onChange={e => {}}
            value=""
            options={[{ label: 'Date Added', value: 'Date Added' }]}
            required
          />
          <SelectField
            keyValue="order"
            name="order"
            onChange={e => {}}
            value=""
            options={[{ label: 'Descending', value: 'Descending' }]}
            required
          />
        </FilterControlsContainer>
      </FilterContainer>
      <PaginationWrapper>
      <InventoryPagination
        pages={pageCount}
        pageNumber={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
      />
      </PaginationWrapper>
    </Container>
  );
}
