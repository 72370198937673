import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';
import theme from './theme';
import store, { persistor } from './state/store';
import { history } from './state/effects/router';
import ROUTES from './constants/routes';

import App from './containers/App';
import RestrictedPage from './containers/LogoutWrapper';

import ScrollToTop from './components/ScrollToTop';
import Sidebar from './containers/Sidebar';
import MainPanel from './components/MainPanel/MainPanel';
import Feed from './components/Feed/Feed';
import Leads from './components/Leads/Leads';
import Reporting from './components/Reporting/Reporting';
import MyAccount from './components/MyAccount/MyAccount';
import Help from './components/Help/Help';
import { InventoryContainer } from './components/InventoryView/InventoryView';
import VehicleDetails from './components/VehicleView/VehicleDetails';

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;

  div::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  div::-webkit-scrollbar:vertical {
    width: 12px;
  }

  div::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #d7d7d7;
    background-color: #3c4e5e;
  }

  div::-webkit-scrollbar-track {
    background-color: #d7d7d7;
  }
`;

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <App>
                <RestrictedPage parent={ROUTES.INDEX}>
                  <Sidebar />
                  <Content>
                    <Switch>
                      <Route path={ROUTES.INDEX} component={MainPanel} exact />
                      <Route
                        path={ROUTES.INVENTORY}
                        component={InventoryContainer}
                        exact
                      />
                      <Route
                        path={ROUTES.VEHICLEVIEW}
                        render={({ match }) => (
                          <VehicleDetails
                            match={match}
                            key={match.params.vin}
                          />
                        )}
                        exact
                      />
                      <Route path={ROUTES.FEEDAUDIT} component={Feed} exact />
                      <Route path={ROUTES.LEADS} component={Leads} exact />
                      <Route
                        path={ROUTES.REPORTING}
                        component={Reporting}
                        exact
                      />
                      <Route
                        path={ROUTES.MYACCOUNT}
                        component={MyAccount}
                        exact
                      />
                      <Route path={ROUTES.HELP} component={Help} exact />
                    </Switch>
                  </Content>
                </RestrictedPage>
              </App>
            </ThemeProvider>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}
