import { lensPath, view } from 'ramda';
import { useEffect } from 'react';

export function useVehicleForm(vehicle, register, setValue) {
  const vehicleLens = (...propPath) => view(lensPath(propPath), vehicle);
  const requiredOption = { required: 'required' };
  const validAphanumerical = /^[A-Z0-9a-z]+$/i;
  const invalidRegMessage = 'Invalid';
  const validRequiredNumberOption = {
    required: 'required',
    maxLength: { value: '10', message: 'must be less that 10 characters' },
    minLength: { value: '1', message: 'required' },
  };

  useEffect(() => {
    setValue('regNo', vehicleLens('regNo', 'value'));
    setValue('registration', vehicleLens('regInitial', 'value'));
    setValue('odometer', vehicleLens('odometer', 'value', 'value'));
    setValue('numberOfSeats', vehicleLens('numberOfSeats', 'value'));
    setValue('retailPrice', vehicleLens('price', 'retailPrice', 'value'));
    setValue('standInValue', vehicleLens('price', 'standInValue', 'value'));
    setValue(
      'preparationCost',
      vehicleLens('price', 'preparationCost', 'value'),
    );
    setValue('tradePrice', vehicleLens('price', 'tradePrice', 'value'));
    setValue('model', vehicle.model);
    setValue('doors', vehicleLens('doors', 'value'));
    setValue('bodyStyle', vehicleLens('bodyStyle', 'bodyType', 'id'));
    setValue('engine', vehicleLens('engine', 'variant', 'id'));
    setValue('fuel', vehicleLens('engine', 'fuel', 'type', 'id'));
    setValue('modelYear', vehicleLens('model', 'year', 'value'));
    setValue('transmission', vehicleLens('model', 'year', 'value'));
    setValue('interiorColour', vehicleLens('colour', 'interiorColour', 'id'));
    setValue('exteriorColour', vehicleLens('colour', 'exteriorColour', 'id'));
  }, [vehicle, setValue]);

  useEffect(() => {
    register({ name: 'registration' }, requiredOption);
    register({ name: 'odometer' }, validRequiredNumberOption);
    register({ name: 'numberOfSeats' }, requiredOption);
    register({ name: 'retailPrice' }, validRequiredNumberOption);
    register({ name: 'tradePrice' }, validRequiredNumberOption);
    register({ name: 'standInValue' }, validRequiredNumberOption);
    register({ name: 'preparationCost' }, validRequiredNumberOption);
    register({ name: 'model' }, requiredOption);
    register({ name: 'doors' }, requiredOption);
    register({ name: 'bodyStyle' }, requiredOption);
    register({ name: 'engine' }, requiredOption);
    register({ name: 'fuel' }, requiredOption);
    register({ name: 'modelYear' }, requiredOption);
    register({ name: 'transmission' }, requiredOption);
    register({ name: 'interiorColour' }, requiredOption);
    register({ name: 'exteriorColour' }, requiredOption);
    register(
      { name: 'regNo' },
      {
        required: 'required',
        pattern: {
          value: validAphanumerical,
          message: invalidRegMessage,
        },
      },
    );
  }, [register, requiredOption, validAphanumerical, validRequiredNumberOption]);
}
