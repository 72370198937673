// @flow
import React from 'react';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import styled from 'styled-components';

import {
  InputFieldSearch,
  SecondaryButton,
  Header,
  ButtonsWrapper,
  ButtonsContainer,
} from '../Common';

import {
  VehicleGeneralSection,
  VehiclePricingSection,
  VehicleEquipmentSection,
  VehicleImageSection,
} from '.';

import Routes from '../../constants/routes';
import { useVehicleDetails } from './useVehicleDetails';
import { useVehicleForm } from './useVehicleForm';
import searchIcon from '../../assets/search-icn.svg';
import spinner from '../../assets/spinner-large-blue.svg';

const ColumnContainer = styled.div`
  display: flex;
  background-color: #efefef;
  flex-grow: 1;
  overflow-y: auto;
  flex-flow: column nowrap;
`;

const ClickGuard = styled.div`
  pointer-events: ${props => (props.enabled ? 'none' : 'auto')};
  height: auto;
  width: 100%;
  z-index: 1;
  opacity: ${props => (props.enabled ? 0.4 : 1)};
  z-index: ${props => (props.enabled ? 2 : 1)};
`;

const Spinner = styled.img`
  height: 34px;
  width: 34px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-flow: column nowrap;
`;

const SearchBar = styled.div`
  background-color: #efefef;
  padding: 15px;
`;

type Props = {
  match: Object,
};

export default function(props: Props) {
  const {
    match: {
      params: { vin },
    },
  } = props;

  const {
    onSubmit,
    vehicle,
    updateVehicle,
    handleVinLookup,
    updateVin,
    vinLookupInput,
    actionErrors,
    routeId,
    isLoading,
    masterData,
  } = useVehicleDetails(vin);

  const { register, errors, handleSubmit, setValue } = useForm();
  const clickGuardEnabled =
    isLoading || !vehicle.vin || actionErrors.length > 0;
  const showVehicleEditor =
    !routeId || (vehicle.vin && routeId && !isLoading && !actionErrors.length);
  const showSpinner = routeId && isLoading;

  const displayDateFormat = 'yyyy-MM-dd';

  const submitFrom = () => {
    updateVehicle(vehicle);
    onSubmit(vehicle);
  };

  useVehicleForm(vehicle, register, setValue);

  return (
    <>
      <Form onSubmit={handleSubmit(submitFrom)}>
        <Header title="Vehicle Details" backButtonRoute={Routes.INVENTORY}>
          <ButtonsWrapper>
            <ButtonsContainer>
              <SecondaryButton type="submit">Save changes</SecondaryButton>
            </ButtonsContainer>
          </ButtonsWrapper>
        </Header>
        <ColumnContainer>
          {!routeId && (
            <SearchBar>
              <InputFieldSearch
                placeholder="Enter Vin..."
                searchText={vinLookupInput}
                isLoading={isLoading}
                onSearchTextUpdate={updateVin}
                onSearch={handleVinLookup}
                keyValue="vinSearch"
                name="vinSearch"
                error={actionErrors[0]}
                icon={searchIcon}
                buttonText="Lookup"
                required
              />
            </SearchBar>
          )}
          {showSpinner && (
            <SpinnerContainer>
              <Spinner src={spinner} alt="Loading..." />
            </SpinnerContainer>
          )}
          {showVehicleEditor && (
            <ClickGuard enabled={clickGuardEnabled}>
              <VehicleGeneralSection
                props={{
                  vehicle,
                  register,
                  updateVehicle,
                  errors,
                  setValue,
                  displayDateFormat,
                  masterData,
                }}
              />
              <VehiclePricingSection
                props={{
                  vehicle,
                  updateVehicle,
                  errors,
                  setValue,
                }}
              />
              <VehicleEquipmentSection
                props={{
                  vehicle,
                  updateVehicle,
                  masterData,
                }}
              />
              <VehicleImageSection
                props={{
                  vehicle,
                  updateVehicle,
                }}
              />
            </ClickGuard>
          )}
        </ColumnContainer>
      </Form>
    </>
  );
};
