import styled from 'styled-components';

const ButtonTemplate = styled.button`
  display: flex;
  height: 35px;
  width: ${props => props.width || 'auto'};
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  border: 1px white solid;
  white-space: nowrap;
`;

const PrimaryButton = styled(ButtonTemplate)`
  background-color: ${props => (props.disabled ? '#DCDCDC' : '#077AFF')};
  color: ${props => (props.disabled ? '#8F8D91' : 'white')};
`;

const SecondaryButton = styled(ButtonTemplate)`
  background-color: #FFFFFF;
  color: ${props => (props.disabled ? '#8F8D91' : '#007AFF;')};
`;

export { PrimaryButton, SecondaryButton };
