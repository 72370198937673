import React from 'react';
import styled from 'styled-components';
import { SelectField } from '../Common';
import cross from '../../assets/cross_icn.svg';

const SectionContainer = styled.div`
  width: 100%;
  background-color: #efefef;
`;

const EquipmentList = styled.ul`
  padding: 0;
  list-style-type: none;
  column-count: 3;
`;

const EquipmentItem = styled.li`
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 1em;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  height: 36px;
  outline: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }
`;
const ContainerTitle = styled.div`
  color: #293845;
  font-size: 18px;
  height: 58px;
  padding-left: 16px;
  font-weight: bold;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
`;

const ContainerFields = styled.div`
  padding: 15px 20px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  background-color: #efefef;
  flex-grow: 1;
  flex-basis: 0;
`;

const RemoveButton = styled.div`
  display: flex;
  cursor: pointer;
  z-index: 1;
  margin-left: auto;
  background-color: #ffffff;
  height: 20px;
  width: 20px;
  justify-content: center;
`;

const removeButton = onRemove => {
  return (
    onRemove && (
      <RemoveButton type="button" onClick={onRemove}>
        <img src={cross} alt="remove" />
      </RemoveButton>
    )
  );
};

export default ({ props }) => {
  const { vehicle, updateVehicle, masterData } = props;

  const dataSource = { manual: 'Manual' };

  const GetSourceProperty = (value, id) => ({
    id: id || '',
    value,
    source: dataSource.manual,
  });

  const onEquipmentSelect = ({ keyValue, value, label }) => {
    const foundIndex = vehicle.equipment.categories.findIndex(
      obj => obj.name.value === keyValue,
    );
    const category = vehicle.equipment.categories[foundIndex];
    const wrapSourceProperty = {
      name: GetSourceProperty(label, value),
      groups: [],
    };
    category.members.push(wrapSourceProperty);

    vehicle.equipment.categories[foundIndex] = category;

    const updated = {
      ...vehicle,
      equipment: {
        categories: vehicle.equipment.categories,
      },
    };
    updateVehicle(updated);
  };

  const equipment =
    vehicle.equipment &&
    vehicle.equipment.categories.find(obj => obj.name.value === 'Equipment');

  const equipmentMembers = (equipment && equipment.members) || [];

  const getFeatures = key => {
    const featureGroup =
      masterData.features.find(obj => obj.label === key) || [];
    const featureOptions = (featureGroup && featureGroup.vehicleOptions) || [];

    return featureOptions.map(feature => ({
      value: feature.id,
      label: feature.label,
    }));
  };

  const deleteFeature = feature => {
    const currentEquipment = vehicle.equipment.categories;

    const equipmentIndex = currentEquipment.findIndex(
      obj => obj.name.value === 'Equipment',
    );
    
    const featureIndex = vehicle.equipment.categories[
      equipmentIndex
    ].members.findIndex(obj => obj.name.value === feature.name.value);

    currentEquipment[equipmentIndex].members.splice(featureIndex, 1);

    const updated = {
      ...vehicle,
      equipment: {
        ...vehicle.equipment,
        categories: currentEquipment,
      },
    };

    updateVehicle(updated);
  };

  return (
    <SectionContainer>
      <ContainerTitle>Fitted Accessories</ContainerTitle>
      <ContainerFields>
        <SelectField
          placeholder="Select Equipment..."
          keyValue="Equipment"
          name="Equipment"
          onChange={onEquipmentSelect}
          label="Add Equipment"
          options={getFeatures('Equipment')}
        />
        <ColumnContainer>
          <Column>
            <EquipmentList>
              {equipmentMembers.map(feature => (
                <EquipmentItem key={feature.name.id}>
                  {feature.name.value}
                  {removeButton(() => deleteFeature(feature))}
                </EquipmentItem>
              ))}
            </EquipmentList>
          </Column>
        </ColumnContainer>
      </ContainerFields>
    </SectionContainer>
  );
};
