// @flow
/* eslint-env browser */
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { lensPath, view } from 'ramda';
import { actions, constants } from '../actions/vehicles';
import { get, post } from '../../helpers/http';
import { pageSize, imsVehiclesUrl } from '../../settings';

function* loadVehicles({ payload }) {
  try {
    const sortField = view(lensPath(['sortParams', 'id']), payload);
    const sortDirection = view(
      lensPath(['sortParams', 'sortingDirection']),
      payload,
    );

    const config = {
      params: {
        languageIsoCode: 'en_GB',
        makeId: 'd17f3c74-f901-4788-8730-50d23fc2571b',
        pageNumber: payload.pageNumber,
        sortField,
        sortDirection,
        searchQuery: payload.searchText,
        pageSize,
      },
    };
    const url = `${imsVehiclesUrl}vehicles/`;

    const response = yield call(get, { url, config });

    yield put(actions.loadVehiclesSuccess(response.data));
  } catch (error) {
    yield put(actions.loadVehiclesFailure({ message: error.message }));
  }
}

function* updateVehicles(token) {
  const updatedVehicles = yield select(state => state.vehicles.batchVehicles);
  const url = `${imsVehiclesUrl}enhanced-vehicles/batch`;

  if (updatedVehicles.length) {
    try {
      yield call(post, {
        url,
        token,
        data: { vehicles: updatedVehicles },
      });

      yield put(actions.updateVehiclesSuccess());
    } catch (error) {
      yield put(actions.updateVehiclesFailure(error.message));
    }
  }
}

export default function* vehiclesSaga() {
  yield takeLatest(constants.loadVehicles, loadVehicles);
  yield takeLatest(constants.updateVehicles, updateVehicles);
}
