// @flow
import { combineReducers } from 'redux';

import authReducer, { type State as AuthState } from './reducers/auth';
import routerReducer, { type State as RouterState } from './reducers/router';
import vehiclesReducer, {
  type State as VehiclesState,
} from './reducers/vehicles';
import masterDataReducer, {
  type State as MasterDataState,
} from './reducers/masterData';

export type State = {
  auth: AuthState,
  router: RouterState,
  vehicles: VehiclesState,
  masterDataState: MasterDataState,
};

export default combineReducers({
  auth: authReducer,
  router: routerReducer,
  vehicles: vehiclesReducer,
  masterData: masterDataReducer,
});
