// @flow
import { constants } from '../actions/masterData';

export type MasterData = {
  bodystyles: Object,
  colours: Object,
  engines: Object,
  features: Object,
  fuelTypes: Object,
  language: Object,
  make: Object,
  transmissions: Object,
  variants: Object,
};

export type State = {
  isLoading: boolean,
  errors: Array,
  masterData: MasterData,
};

const initialState: State = {
  errors: [],
  vehicle: {
    approved: {},
    numberOfSeats: {},
    media: { images: [] },
    vehicleStatus: { value: {} },
    regInitial: { value: {} },
    make: {},
    model: { name: {}, year: {} },
    bodyStyle: { bodyType: { id: {} } },
    odometer: { units: { value: {} }, value: {} },
    wheelDrive: { value: {} },
    type: { value: {} },
    engine: { id: {}, fuel: { type: { id: {} } }, variant: {} },
    transmission: { id: {} },
    colour: { interiorColour: { id: {} }, exteriorColour: { id: {} } },
    doors: { value: {} },
    price: {
      retailPrice: { value: 0 },
      standInValue: { value: 0 },
      tradePrice: { value: 0 },
      preparationCost: { value: 0 },
    },
    equipment: { categories: [] },
    inspectionCertificateNumber: {},
    entryDate: {},
  },
  masterData: {
    bodystyles: [],
    colours: [],
    engines: [],
    features: [],
    fuelTypes: [],
    transmissions: [],
    variants: [],
  },
  models: [],
  isLoading: false,
};
const generateError = (id, message) => ({ id, message });
export default function reducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case constants.loadVehicle:
      return {
        ...initialState,
        isLoading: true,
      };
    case constants.loadVehicleSuccess: {
      const vehicle = action.payload;

      const media =
        vehicle.media && vehicle.media.images ? vehicle.media : { images: [] };

      return {
        ...state,
        vehicle: { ...vehicle, media },
        errors: state.errors.filter(
          ({ id }) => id !== constants.loadVehicleFailure,
        ),
      };
    }
    case constants.saveVehicleSuccess: {
      const vehicle = action.payload;
      return {
        ...state,
        vehicle,
        errors: state.errors.filter(
          ({ id }) => id !== constants.saveVehicleFailure,
        ),
      };
    }
    case constants.loadMasterDataSuccess: {
      const masterDataResult = action.payload;
      return {
        ...state,
        masterData: masterDataResult,
        errors: state.errors.filter(
          ({ id }) => id !== constants.loadMasterDataFailure,
        ),
      };
    }
    case constants.loadMakeModelOptionsSuccess: {
      const models = action.payload;
      return {
        ...state,
        models,
        errors: state.errors.filter(
          ({ id }) => id !== constants.loadMakeModelOptionsFailure,
        ),
      };
    }
    case constants.loadVehicleDetailsSuccess: {
      return {
        ...state,
        isLoading: false,
        errors: [],
      };
    }
    case constants.loadVehicleFailure:
      return {
        ...state,
        errors: [
          ...state.errors,
          generateError(action.type, action.payload.message),
        ],
        isLoading: false,
      };
    case constants.loadMakeModelOptionsFailure:
      return {
        ...state,
        errors: [
          ...state.errors,
          generateError(action.type, action.payload.message),
        ],
        isLoading: false,
      };
    case constants.loadMasterDataFailure:
      return {
        ...state,
        errors: [
          ...state.errors,
          generateError(action.type, action.payload.message),
        ],
        isLoading: false,
      };
    case constants.saveVehicleFailure:
      return {
        ...state,
        errors: [
          ...state.errors,
          generateError(action.type, action.payload.message),
        ],
      };
    case constants.loadVehicleDetailsFailure:
      return {
        ...state,
        isLoading: false,
        errors: [
          ...state.errors,
          generateError(action.type, action.payload.message),
        ],
      };
    default:
      return state;
  }
}
