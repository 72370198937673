// @flow
import React from 'react';
import styled from 'styled-components';

function onClickHandler(e) {
  e.stopPropagation();
}

const Wrapper = styled.div`
    width: 100%;
    padding: 0;
  `;

export default ({ children }) => {
  return (
    <Wrapper role="complementary" onClick={onClickHandler}>
      {children}
    </Wrapper>
  );
};
